const isIE = window.navigator.userAgent.indexOf("MSIE") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const environment = {
  production: true,
  adalConfig: {
    tenant: 'chevron.onmicrosoft.com',
    clientId: '3db9976b-29ca-477c-91a9-7dc7de29e8de',
    postLogoutRedirectUri: 'http://localhost:44300/logout',
    endpoints: {
      'https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885': 'https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885'
    },
  },
  msalConfig:{
    auth: {
      clientId: '3db9976b-29ca-477c-91a9-7dc7de29e8de',
      authority: "https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26/",
      validateAuthority: true,
      redirectUri: "https://mobileturnaround-test-dr-cvx.azurewebsites.net/",
      postLogoutRedirectUri: "https://mobileturnaround-test-dr-cvx.azurewebsites.net/logout",
      navigateToLoginRequestUrl: true,
      user_impersonation:'https://turnaroundms-test.azure.chevron.com/user_impersonation',
    },
    
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    angular:{
      popUp: false,
      consentScopes: [
        "user.read",
        "openid",
        "profile"
      ],
      protectedResourceMap: [
        ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
      ],
      "extraQueryParameters": {}
    }
  },
  TCOInstanceId: ['5'],
  appCode: 'webApp',
  apiEndpoint: 'https://mobileturnaround-api-test-dr-cvx.azurewebsites.net/api'
};
